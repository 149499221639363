<template>
    <div>
        <el-table :data="list">
            <el-table-column
                prop="标讯"
                label="标题"
                min-width="40px"
            >
                <template slot-scope="{ row }">
                    {{ row?.biaoxun?.title }}
                </template>
            </el-table-column>


            <el-table-column
                prop="created_at"
                label="浏览时间"
                width="width"
                min-width="40px"
            ></el-table-column>
        </el-table>
        <pagination
            :total="total"
            :page.sync="searchData.page"
            :limit.sync="searchData.pagesize"
            @pagination="getList"
        />
    </div>
</template>

<script>
import {getBrowsingHistoryListAPI} from "../api";

export default {
    name: "browsingHistory",
    props: {
        uid: {
            type: [Number, String],
            default() {
                return 0
            }
        }
    },
    data() {
        return {
            list: [],
            searchData: {
                page: 1,
                pagesize: 10,
            },
            total: 0,
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        async getList() {
            // 获取get 参数
            let params = {...this.searchData}
            params.uid = this.uid
            const res = await getBrowsingHistoryListAPI(params)
            this.list = res.data || []
            this.total = res.total || 0
        },
    }
}
</script>

<style scoped lang="scss">

</style>
