<template>
  <div>
    <el-empty
      v-if="list.length === 0"
      description="暂无数据"
    ></el-empty>
  <!-- <div class="block" style="margin: 0px auto; width: 500px;">
  <div class="radio" style="margin: 25px;">
    排序：
    <el-radio-group v-model="reverse" >
      <el-radio :label="true">倒序</el-radio>
      <el-radio :label="false">正序</el-radio>
    </el-radio-group>
  </div>
    <el-timeline :reverse="reverse">
    <el-timeline-item
      v-for="(item, index) in list"
      :key="index"
      :timestamp="item.created_at">
      {{item.content}}
    </el-timeline-item>
  </el-timeline>
  </div> -->
  <div class="block" v-else>
  <el-timeline>
    <el-timeline-item v-for="(item, index) in list" :key="index" :timestamp="item.created_at" placement="top">
      <el-card>
        <h4>内容：{{ item.content }}</h4>
<!--        <h4>备注：{{ item.remark }}</h4>-->
        <p> 提交于 {{ item.created_at }}</p>
      </el-card>
    </el-timeline-item>
  </el-timeline>
</div>
  </div>
</template>

<script>
import { followLogListAPI } from '../api'

export default {
  name: "FollowLog",
  props: {
    uid: {
      type: Number,
      require: true,
      default: () => 0
    }
  },
  data () {
    return {
      reverse: false,
      list: []
    }
  },
  mounted () {
  },
  methods: {
    async getList () {
      const params = {
        uid: this.uid
      }
      this.list = await followLogListAPI(params)
    },
    resetList () {
      this.list = []
    }
  }
}
</script>

<style scoped lang="scss">
</style>
