<template>
  <div>
    <el-empty v-if="!inviteInfo" description="暂无数据"></el-empty>
    <el-descriptions
      v-else
      :column="1"
      border
    >
      <el-descriptions-item label="ID">{{inviteInfo.id}}</el-descriptions-item>
      <el-descriptions-item label="昵称">{{inviteInfo.nickname}}</el-descriptions-item>
      <el-descriptions-item label="手机号码">{{inviteInfo.mobile}}</el-descriptions-item>
      <el-descriptions-item label="头像">
        <el-image
            style="width: 60px; height: 60px; border-radius: 5px"
            :src="inviteInfo.face"
            :preview-src-list="[inviteInfo.face]"
            fit="cover"
        ></el-image>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "InviteUserInfo",
  props: {
    inviteInfo: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  }

}
</script>

<style scoped lang="scss">
</style>
