<template>
  <el-table
      :data="list"
      style="width: 100%">
    <el-table-column
        prop="order_no"
        label="订单号"
        width="180">
    </el-table-column>
    <el-table-column
        prop="product_name"
        label="产品名称"
        width="180">
    </el-table-column>
    <el-table-column
        prop="pay_price"
        label="付款"
        width="180">
    </el-table-column>
    <el-table-column
        prop="pay_type_dsc"
        label="支付方式">
    </el-table-column>
    <el-table-column
        prop="transaction_id"
        label="交易流水号">
    </el-table-column>
    <el-table-column
        prop="created_at"
        label="下单时间">
    </el-table-column>
  </el-table>
</template>

<script>
import {getOrderListAPI} from '../api'

export default {
  name: "orderManage",
  props: {
    uid: {
      type: [Number, String],
      default() {
        return 0
      }
    }
  },

  data() {
    return {
      list: []
    }
  },

  mounted() {
    getOrderListAPI(this.uid).then(res => {
      this.list = res.data || []
    })
  },
}
</script>

<style scoped lang="scss">

</style>
