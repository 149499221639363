<template>
  <el-drawer
      title="用户详情"
      :visible.sync="show_detail"
      direction="rtl"
      size="60%"
      :key="key"
      @opened="opened"
      @close="clearData"
  >
    <div class="wrapper">
      <div class="user-box">
        <el-image
            style="width: 60px; height: 60px; border-radius: 5px"
            :src="detail.face"
            :preview-src-list="[detail.face]"
            fit="cover"
        ></el-image>
        <div class="user">
          <div class="name">{{ detail.nickname }}</div>
          <div class="mobile">{{ detail.mobile }}</div>
        </div>
      </div>

      <!-- tab -->
      <div class="tabs-box">
        <el-tabs
            v-model="activeName"
            @tab-click="handleTabClick"
            @tab-remove="handleTabRemove"
        >
          <el-tab-pane
              label="基本信息"
              name="basicInfo"
          >
            <el-button style="float: right; margin: 10px;" @click="dialogVisible = true" type="primary" size="mini">
              编辑
            </el-button>
            <el-descriptions
                class="margin-top"
                :column="2"
                border
            >
              <el-descriptions-item>
                <template slot="label">
                  用户ID
                </template>
                {{ uid }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-user"></i>
                  用户昵称
                </template>
                {{ detail.nickname }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  企业名称
                </template>
                {{ detail.company_name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-mobile-phone"></i>
                  联系方式
                </template>
                {{ detail.mobile }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  注册日期
                </template>
                {{ detail.created_at }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  到期时间
                </template>
                {{ detail.expire_date }}
              </el-descriptions-item>
            </el-descriptions>
          </el-tab-pane>
          <el-tab-pane
              label="邀请人信息"
              name="inviteUserInfo"
          >
            <invite-user-info :invite-info="detail.invite_user"></invite-user-info>
          </el-tab-pane>
          <el-tab-pane name="orderInfo" label="订单信息" v-if="uid">
            <order-info :uid="uid"></order-info>
          </el-tab-pane>
          <el-tab-pane name="orderGive" label="赠送" v-if="uid">
            <order-give-manage :uid="uid"></order-give-manage>
          </el-tab-pane>
          <el-tab-pane label="浏览记录" name="browsingHistory" v-if="uid">
            <browsing-history :uid="uid"></browsing-history>
          </el-tab-pane>
          <el-tab-pane
              label="跟进记录"
              name="followLog"
              v-if="uid"
          >
            <follow-log
                ref="followLog"
                :uid="uid"
            ></follow-log>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
        append-to-body
        title="修改"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form ref="form" :model="userInfoForm" label-width="80px">
        <el-form-item label="企业名称">
          <el-input v-model="userInfoForm.company_name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="onSubmit">修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </el-drawer>
</template>

<script>
import {getDetailAPI, updateUserInfoAPI} from './api'
import orderInfo from "./components/orderManage.vue"
import orderGiveManage from "./components/orderGiveManage.vue"
import inviteUserInfo from "@/views/user/components/inviteUserInfo.vue"
import followLog from "@/views/user/components/followLog.vue"
import browsingHistory from "@/views/user/components/browsingHistory.vue";

export default {
  name: 'Detail',
  components: {inviteUserInfo, followLog, orderInfo, orderGiveManage, browsingHistory},
  props: {},
  data() {
    return {
      show_detail: false,
      activeName: 'basicInfo',
      list: [],
      total: 0,
      page: 1,
      key: 1,
      pagesize: 10,
      inviter_page: 1,
      inviter_pagesize: 10,
      inviter_total: 0,
      inviter_list: [],
      uid: 0,
      detail: {},

      dialogVisible: false,
      userInfoForm: {
        id: 0,
        company_name: ''
      },
    }
  },

  watch: {},

  mounted() {
  },

  methods: {
    handleClose() {

    },

    onSubmit() {
      this.$confirm('确认修改么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateUserInfoAPI(this.userInfoForm).then(() => {
          this.$message.success('修改成功')
          this.dialogVisible = false
          this.getDetail()
          this.$emit('modify-success')
        })
      })
    },

    handleTabRemove(tab) {
      // 可以在这里做额外的处理
      if (tab.name === 'followLog') {
        this.$refs.followLog.resetList()
      }
    },
    handleTabClick(tab, event) {
      // 可以在这里做额外的处理
      if (tab.name === 'followLog') {
        this.$refs.followLog.getList()
      }
    },
    opened() {
      this.uid = this.id
      this.getDetail()
    },
    clearData() {
      // 清空数据
      console.log('clearData')
      this.activeName = 'basicInfo'
      this.uid = 0;
      this.key++
    },
    // 详情
    async getDetail() {
      this.detail = await getDetailAPI(this.id)
      this.userInfoForm = {
        id: this.uid,
        company_name: this.detail.company_name,
      }
      // this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .user-box {
    display: flex;
    padding: 20px;
    // border-bottom: 1px solid #f5f5f5;
    .user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      .name {
        font-weight: 600;
      }
    }
  }

  .user-box-item {
    padding: 20px;
    // border-bottom: 1px solid #f5f5f5;
    .user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      .name {
        font-weight: 600;
      }
    }
  }

  .tabs-box {
    padding: 0 20px;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 280px);
    gap: 20px;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(180deg, #a5a5a5 0%, #7a7a7a 100%);
      height: 140px;
      width: 280px;
      border-radius: 5px;
      padding: 20px;

      .bank,
      .name,
      .no {
        background: linear-gradient(to right, #e2e0e0, #faf1f1);
        /* 从左到右渐变 */
        -webkit-background-clip: text;
        /* Safari/Chrome */
        -webkit-text-fill-color: transparent;
        /* Safari/Chrome */
        color: transparent;
        /* Firefox/Opera */
        font-weight: 600;
      }

      .bank {
        font-size: 18px;
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
      }

      .no {
        font-size: 16px;
      }
    }
  }

  .balance {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;

    .item {
      text-align: center;

      .label {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .pagination-container {
    padding: 0 20px !important;
  }

  .inviter {
    display: inline-flex;
    align-items: center;
    background: #f8f8f9;
    border-radius: 5px;
    padding: 0 20px;

    .inviter-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .inviter-name {
        font-weight: 600;
      }
    }
  }

  .balance-box {
    display: flex;
    // align-items: center;
    .balance-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: #f5f5f5;
      border-radius: 5px;
      padding: 0 20px;
      margin-right: 20px;

      span {
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
